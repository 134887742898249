import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Container } from './nav.css';
import NavItems from './nav-items';

const Nav = ({ locale }) => (
  <StaticQuery
    query={graphql`
      query MenuQuery {
        allBuroBorkMenu(filter: { name: { eq: "Primary" } }) {
          edges {
            node {
              id
              field
              name
              locale
            }
          }
        }
      }
    `}
    render={data => {
      let menu = data.allBuroBorkMenu.edges.find(
        edge => edge.node.locale === locale
      );

      return (
        <Container>
          <NavItems items={menu.node.field} />
        </Container>
      );
    }}
  />
);

Nav.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default Nav;
