import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { pageWidth } from 'constants/theme';

export const Container = styled.header`
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    height: 100px;
    /* max-width: ${pageWidth}; */
    padding: 0 16px;
    margin: 0 auto;
    img {
      max-height: 100px;
      ${MEDIA.TABLET`
      max-width: 100%;
    `};
    }
    ${MEDIA.TABLET`
      max-height: 80px;
      height: 80px;
    `};
  }
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  .logo {
    font-size: 45px;
    font-family: 'Saucer BB';
    display: flex;
    align-items: center;
    color: #000;
    ${MEDIA.TABLET`
    font-size: 25px;
    `}
  }

  a {
    color: #757575;
    transition: color 0.2s ease;
    text-decoration: none;
    &:hover {
      color: inherit;
    }
  }
  .navigation-toggle {
    display: none;
  }
  ${MEDIA.TABLET`
    height: 80px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
    margin: 0;
    background: #fff;
    .logo {
      max-height: 80px;
    }
    .navigation-toggle {
      height: 80px;
      max-height: 80px;
      margin-left: auto;
      z-index: 1;
      display: flex;
      align-items: center;
      padding: 0 24px;
      background: #fff;
      box-shadow: -4px 0 8px rgba(0,0,0,.25);
      svg {
        width: 24px;
      }
    }
  `};
`;

export const Navigation = styled.div`
  margin: 0 32px;
  background: #fff;
  display: flex;
  align-items: center;
  ${MEDIA.TABLET`
    display: flex;
    position: fixed;
    flex-direction: column;
    z-index: 9999;
    background: #fff;
    top: 0;
    transition: all 0.5s ease;
    height: 0;
    width: 0;
    opacity: 0;
    right: -100%;
    ${props =>
      props.show
        ? `
      top: 80px;
      width: 100%;
      height: 100%;
      right: 0;
      opacity: 1;
      margin: 0;
      nav {
        padding: 16px;
        order: 1;
      }

      ul {
        flex-direction: column;
        li {
          width: 100%;
          margin: 0 !important;
        }
      }
    `
        : ''}
  `};
`;
