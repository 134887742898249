import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Logo from 'icons/logo.svg';
import { Container, Column, Copyright } from './footer.css';
import Contact from './contact';

const Reseller = () => {
  if (process.env.GATSBY_RESELLER === 'BUKAZU') {
    return (
      <a href="https://bukazu.com" title="Boekingssyteem voor verhuurobjecten">
        <Logo width="120px" height="12px" />
      </a>
    );
  } else {
    return (
      <a
        href="https://burobork.nl"
        title="Website gemaakt door Buro Bork"
        style={{
          color: 'rgb(48, 70, 125)',
          textDecoration: 'none',
        }}
      >
        Buro Bork
      </a>
    );
  }
};

const Footer = () => (
  <React.Fragment>
    <Container>
      <Column>
        <h3>
          <FormattedMessage id="contact" />
        </h3>
        <Contact />
      </Column>
      <Column />
      <Column />
      <Column />
    </Container>
    <Copyright>
      <div>
        Copyright
        <span>{new Date().getFullYear()}</span>
      </div>
      <div>
        Ontworpen en gerealiseerd door: <Reseller />
      </div>
    </Copyright>
  </React.Fragment>
);

Footer.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default Footer;
