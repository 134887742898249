import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { IntlProvider, addLocaleData } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import enData from 'react-intl/locale-data/en';
import nlData from 'react-intl/locale-data/nl';
import deData from 'react-intl/locale-data/de';
import Head from 'components/head';
import Header from 'components/header';
import GlobalStyle from 'global.css.js';
import Footer from 'components/footer';

import en from 'locales/en.json';
import nl from 'locales/nl.json';
import de from 'locales/de.json';

import { Container } from './layout.css';

const messages = { en, nl, de };

addLocaleData([...enData, ...nlData, ...deData]);

const Layout = ({ data, locale, children }) => (
  <IntlProvider locale={locale} messages={messages[locale]}>
    <div>
      <GlobalStyle />
      <Head />
      <Header title={data.title} logo={data.logo} locale={locale} />
      <Container>{children}</Container>
      <div
        style={{
          background: '#fff',
        }}
      >
        <Footer locale={locale} />
      </div>
    </div>
  </IntlProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  locale: PropTypes.string,
};

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        buroBorkSite {
          id
          field
        }
      }
    `}
    render={data => {
      const parsedData = JSON.parse(data.buroBorkSite.field);
      const linkColor = parsedData['color-link'] || parsedData.primary_color;
      const theme = { primaryColor: parsedData.primary_color, linkColor };
      return (
        <ThemeProvider theme={theme}>
          <Layout data={JSON.parse(data.buroBorkSite.field)} {...props} />
        </ThemeProvider>
      );
    }}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWithQuery;
